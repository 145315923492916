<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">请假管理</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="content">
      <van-tabs v-model="active" type="card" swipeable sticky>
        <van-tab title="待审核" style="margin-top: 20px;">
          <div class="reverse">
            <van-list
              v-if="list && list.length > 0"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onLoad"
            >
              <div class="reverse">
                <div v-for="(item, index) in list" :key="index" class="dish-card">
                  <div class="card">
                    <div class="eat-time" style="padding-top: 0.15rem">
                      <div>申请人</div>
                      <div>{{ item.userName }}</div>
                    </div>
                    <div class="eat-time">
                      <div>申请时间</div>
                      <div>{{ item.createTime }}</div>
                    </div>
                    <div class="eat-time">
                      <div>开始时间</div>
                      <div>{{ item.startTime + ' 00:00:00' }}</div>
                    </div>
                    <div class="eat-time">
                      <div>结束时间</div>
                      <div>{{ item.endTime + ' 23:59:59' }}</div>
                    </div>
                    <div class="eat-time">
                      <div>请假理由</div>
                      <div style="max-height: 7rem;">{{ item.description }}</div>
                    </div>
                    <div class="eat-time" style="border-bottom: none;justify-content: flex-end">
                      <div class="btn" @click="examineLeave(item.id, 2)">拒绝</div>
                      <div class="btn" @click="examineLeave(item.id,1)">同意</div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          </div>
        </van-tab>
        <van-tab title="已审核" style="margin-top: 20px;">
          <van-list
            v-if="examineList && examineList.length > 0"
            v-model="examineLoading"
            :finished="examineFinished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onLoad"
          >
            <div class="reverse">
              <div v-for="(item, index) in examineList" :key="index" class="dish-card">
                <div class="card">
                  <div class="eat-time" style="padding-top: 0.15rem">
                    <div>状态</div>
                    <div>{{ item.status === 1 ? '同意' : item.status === 2 ? '拒绝' : '' }}</div>
                  </div>
                  <div class="eat-time" style="padding-top: 0.15rem">
                    <div>申请人</div>
                    <div>{{ item.userName }}</div>
                  </div>
                  <div class="eat-time">
                    <div>申请时间</div>
                    <div>{{ item.createTime }}</div>
                  </div>
                  <div class="eat-time">
                    <div>开始时间</div>
                    <div>{{ item.startTime + ' 00:00:00' }}</div>
                  </div>
                  <div class="eat-time">
                    <div>结束时间</div>
                    <div>{{ item.endTime + ' 23:59:59' }}</div>
                  </div>
                  <div class="eat-time" style="border-bottom: none">
                    <div>请假理由</div>
                    <div>{{ item.description }}</div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty v-else description="暂无数据" style="padding-top: 40%" />
        </van-tab>
      </van-tabs>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getExamineList, editCwLeave } from "@/api/leave";
import cancel from "@/assets/cancel.png";
import { Dialog } from "vant";

export default {
  name: "CheckWork",
  data() {
    return {
      examineList: [],
      examinePagination: {
        current: 1,
        size: 10,
        total: 0,
      },
      examineLoading: false,
      examineFinished: false,
      active: 0,
      title: "",
      show: false,
      cancel,
      list: [],
      loading: false,
      finished: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  computed: {
    date() {
      return (day) => {
        if (day) {
          return day.substr(-8);
        } else {
          return "-";
        }
      };
    },
  },
  created() {
    const { role } = this.$store.getters;
    this.title = role === 0 ? "主管" : "班主任";
    const { pagination, examinePagination } = this;
    // const { current, size } = this.pagination
    this.fetch(pagination);
    this.examineFetch(examinePagination);
  },
  methods: {
    examineLeave(id, status) {
      const message = status === 2 ? "确定拒绝该申请？" : "确定同意该申请？";
      Dialog.confirm({
        title: "审批",
        message,
      })
        .then(async () => {
          const { code, message } = await editCwLeave({ id, status });
          if (code === 0) {
            this.list = [];
            this.examineList = [];
            this.pagination = {
              current: 1,
              size: 10,
              total: 0,
            };
            this.examinePagination = {
              current: 1,
              size: 10,
              total: 0,
            };
            this.fetch(this.pagination);
            this.examineFetch(this.examinePagination);
          } else {
            console.log(message);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async fetch(params) {
      const { list } = this;
      list.length === 0 && (this.show = true);
      const { code, data, message } = await getExamineList({
        ...params,
        status: 0,
      });
      if (code === 0) {
        this.list = [...list, ...data.records];
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    async examineFetch(params) {
      const { examineList } = this;
      examineList.length === 0 && (this.show = true);
      const { code, data, message } = await getExamineList({
        ...params,
        status: 1,
      });
      if (code === 0) {
        this.examineList = [...examineList, ...data.records];
        this.examinePagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        setTimeout(() => {
          this.examineLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    toHome() {
      this.$router.back();
    },
    onLoad() {
      const { current, size, total } = this.examinePagination;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.fetch({ current: current + 1, size });
      }
    },
    onExamineLoad() {
      const { current, size, total } = this.pagination;
      if (current * size >= total) {
        this.examineFinished = true;
      } else {
        this.examineFetch({ current: current + 1, size });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding: 4rem 1rem 0 1rem;
    .add {
      background-color: #1989fa;
      color: #fff;
      display: inline-block;
      padding: 0.4rem 2rem;
      border-radius: 3rem;
      margin-bottom: 1rem;
    }
    .reverse {
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          color: #fff;
        }
        .btn:first-child {
          background-color: #f56c6c;
        }
        .btn:nth-child(2) {
          background-color: #409eff;
          margin-left: 10px;
        }
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
::v-deep .van-tabs__nav--card {
  border: 1px solid #607483;
}
::v-deep .van-tab.van-tab--active {
  background-color: #607483 !important;
}
::v-deep .van-tab {
  color: #607483;
  border-right: 1px solid #607483;
}
</style>
